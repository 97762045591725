import URLS from '@/apis/BackEndAPIEndpoints'
import { RequiredArguments } from '@/helpers/methods'
import { Backend } from '@/plugins/axios/Configurator'

export default {
	node: {
		workflows: {
			create: function (vendorId, nodeId, workflowId) {
				RequiredArguments({ vendorId, nodeId, workflowId })
				return Backend.POST(URLS.api.vendors.nodes.workflows.uri, [vendorId, nodeId, workflowId])
			},
			read: function (vendorId, nodeId) {
				RequiredArguments({ vendorId, nodeId })
				return Backend.GET(URLS.api.vendors.nodes.workflows.uri, [vendorId, nodeId])
			},
			delete: function (vendorId, nodeId, workflowId) {
				RequiredArguments({ vendorId, nodeId, workflowId })
				return Backend.DELETE(URLS.api.vendors.nodes.workflows.uri, [vendorId, nodeId, workflowId])
			}
		},
		workflowStatuses: {
			create: function (vendorId, nodeId, workflowStatusId) {
				RequiredArguments({ vendorId, nodeId })
				return Backend.POST(URLS.api.vendors.nodes.workflowStatuses.uri, [vendorId, nodeId, workflowStatusId])
			},
			read: function (vendorId, nodeId, params = {}) {
				RequiredArguments({ vendorId, nodeId })
				return Backend.GET(URLS.api.vendors.nodes.workflowStatuses.uri, [vendorId, nodeId], params)
			}
		}
	},
	vendor: {
		users: {
			read: function (accountingFirmId, vendorId) {
				return Backend.GET(URLS.api.accountingFirms.vendors.users.uri, [accountingFirmId, vendorId])
			}
		},
		workflow: {
			create: function (vendorId, workflowData) {
				return Backend.POST(URLS.api.vendors.workflows.uri, [vendorId], null, workflowData)
			},
			delete: function (vendorId, workflowId) {
				RequiredArguments({ workflowId })
				return Backend.DELETE(URLS.api.vendors.workflows.uri, [vendorId, workflowId])
			},
			read: function (vendorId, workflowId, params) {
				RequiredArguments({ workflowId })
				return Backend.GET(URLS.api.vendors.workflows.uri, [vendorId, workflowId], params)
			},
			update: function (vendorId, workflowId, workflowData) {
				RequiredArguments({ workflowId })
				return Backend.PATCH(URLS.api.vendors.workflows.uri, [vendorId, workflowId], null, workflowData)
			}
		},
		workflows: {
			read: function (vendorId, params = {}, doCancelPreviousRequest = false) {
				return Backend.GET(URLS.api.vendors.workflows.uri, [vendorId], params, null, doCancelPreviousRequest)
			}
		}
	}
}
