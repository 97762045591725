<template>
	<div data-test="workflow-panel">
		<v-expansion-panel
			:disabled="loading"
			class="w-bordered-panel">
			<v-expansion-panel-content>
				<template v-slot:header>
					<v-flex
						v-t="'customers.tabs_title.workflow'"
						class="w-expansion-panel-title"
					/>
				</template>

				<div v-show="loadingNodeWorkflows" class="px-3 mb-4">
					<v-progress-linear color="primary" indeterminate :height="2" />
				</div>

				<v-list-tile v-for="workflow in activeWorkflows" :key="workflow.id">
					<v-list-tile-content>
						<SelectWithChips
							data-test="workflow-panel-select"
							:disabled="isGuest || loadingNodeWorkflows || isWorkflowLoading(workflow.id)"
							:items="workflow.statuses"
							:item-clearable="false"
							item-short-text="short_title"
							item-text="name"
							item-value="id"
							:label="workflow.name"
							list-with-chips
							:loading="isWorkflowLoading(workflow.id)"
							return-object
							style="width: 100%"
							:value="findSelectedWorkflowStatus(workflow)"
							with-short-text-tooltip
							@change="updateWorkflowStatus(workflow.id, $event, true)"
						>
							<template v-slot:item-action="{ value }">
								<v-icon :color="value ? 'primary' : 'transparent'" small class="mr-1">
									check
								</v-icon>
							</template>
						</SelectWithChips>
					</v-list-tile-content>

				</v-list-tile>
				<v-container
					v-if="userHasWriteAccess"
					data-test="workflow-panel-switch"
					class="pt-2 pb-4"
				>
					<w-switch
						v-for="workflow in workflows"
						:key="workflow.id"
						v-model="workflow.isActive"
						:loading="loadingNodeWorkflows"
						:disabled="loadingNodeWorkflows"
						class="justify-space-between"
						@click.stop.prevent="onToggleWorkflowClick(workflow)"
					>
						<template v-slot:prepend>
							<label :class="labelClass(workflow.isActive)">{{ workflow.name }}</label>
						</template>
					</w-switch>

					<div v-if="!hasInitialWorkflows">
						<p>
							{{ $t('workflows.ecm.none') }}
						</p>
						<w-btn
							class="d-block mx-auto mt-3 mb-4"
							@click="goToWorkflowDrawer"
						>
							{{ $t('workflows.actions.create') }}
						</w-btn>
					</div>
				</v-container>
			</v-expansion-panel-content>
		</v-expansion-panel>

	</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import SelectWithChips from "@/components/Commons/SelectWithChips.vue";
import WorkflowManagerModuleGuard from "@/mixins/ModulesGuards/Workflow/WorkflowManagerModuleGuard";

export default {
	name: 'WorkflowPanel',
	components: { SelectWithChips },
	mixins: [WorkflowManagerModuleGuard],
	data() {
		return {
			hasInitialWorkflows: true,
			loading: true,
			loadingStatuses: {},
			loadingNodeWorkflows: true,
			selectedWorkflowsStatuses: {},
			workflows: [],
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			isGuest: state => state.company.userRoles.isGuest,
			selectedNode: state => state.documents.selection.current,
			isDarkModeEnabled: state => state.user.darkMode
		}),
		...mapGetters({
			userHasWriteAccess: 'workflows/userHasWriteAccess'
		}),
		activeWorkflows: function() {
			return this.workflows.filter(({isActive}) => isActive)
		},
		isWorkflowLoading() {
			return workflowId => this.loading || this.loadingStatuses[workflowId];
		},
	},
	watch: {
		selectedNode: {
			handler(value, oldValue) {
				if (value.id === oldValue?.id) {
					return;
				}
				this.loadingStatuses = {};
				this.loadingNodeWorkflows = true;
				this.selectedWorkflowsStatuses = {};
				this.workflows.forEach(workflow => {
					workflow.isActive = false;
				});
				this.loadNodeWorkflows()
			},
			immediate: true
		}
	},
	async created() {
		await this.loadVendorWorkflows()
	},
	methods: {
		...mapMutations({
			setWorkflows: 'workflows/setWorkflows',
			replaceWorkflow: 'workflows/replaceWorkflow',
			removeWorkflow: 'workflows/removeWorkflow',
			addVersionWorkflowStatus: 'workflows/addVersionWorkflowStatus',
		}),
		goToWorkflowDrawer() {
			this.appService.goTo(
				{
					name: this.isAccountant ? 'customer-workflows-drawer' : 'company-settings-workflows-drawer',
					params: {
						workflowId: 'create',
					},
					query: {
						backLink: this.$route.path
					}
				},
				true
			)
		},
		async onToggleWorkflowClick(workflow) {
			if (workflow.isActive) {
				const confirmed = await this.$dialog.confirm({
					text: this.$t('workflows.actions.ecm.delete_workflow.confirmation_text', { name: workflow.name }),
					title: this.$t('workflows.actions.ecm.delete_workflow.confirmation_title', { name: workflow.name }),
					actions: {
						false: this.$t('actions.cancel'),
						true: {
							text: this.$t('actions.delete'),
							color: 'error',
							flat: false,
						}
					}
				});
				if (!confirmed) {
					return;
				}
			}

			workflow.isActive = !workflow.isActive;
			await this.onToggleWorkflow(workflow);
		},
		async onToggleWorkflow(workflow) {
			const method = workflow.isActive ? 'createNodeWorkflow' : 'deleteNodeWorkflow';
			const data = await this.service[method](this.vendorId, this.selectedNode.id, workflow.id)
			if (workflow.isActive) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workflows.activated'));
				this.replaceWorkflow(workflow);
				await this.loadWorkflowStatuses([data.workflow_id]);
				if (!this.selectedWorkflowsStatuses[workflow.id]) {
					this.selectedWorkflowsStatuses[workflow.id] = workflow.statuses[0].id;
				}
				return;
			}
			delete this.selectedWorkflowsStatuses[workflow.id];
			this.removeWorkflow({ workflowId: workflow.id, versionId: this.selectedNode.latestVersion.id });
		},
		async updateWorkflowStatus(workflowId, status, sync = false) {
			const statusId = sync ? status.id : status.workflow_status_id;
			if (this.selectedWorkflowsStatuses[workflowId] === statusId) {
				return
			}
			this.selectedWorkflowsStatuses[workflowId] = statusId
			if (sync) {
				const newStatus = await this.service.createNodeWorkflowStatus(this.vendorId, this.selectedNode.id, statusId)
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workflows.status_updated'));
				this.addVersionWorkflowStatus({ status: newStatus, versionId: this.selectedNode.latestVersion.id });
				return;
			}
			this.addVersionWorkflowStatus({ status, versionId: this.selectedNode.latestVersion.id });
		},
		findSelectedWorkflowStatus(workflow) {
			if (this.loading || this.loadingStatuses[workflow.id]) {
				return [];
			}
			return workflow.statuses.find(status => status.id === this.selectedWorkflowsStatuses[workflow.id]) ?? workflow.statuses[0]
		},
		async loadNodeWorkflows() {
			this.loadingNodeWorkflows = true
			const nodeWorkflows = await this.service.listNodeWorkflows(this.vendorId, this.selectedNode.id)
			const activeWorkflowIds = new Set(nodeWorkflows.map(nw => nw.workflow_id));

			this.workflows.forEach(wf => {
				wf.isActive = activeWorkflowIds.has(wf.id);
			});

			activeWorkflowIds.forEach(workflowId => {
				this.loadingStatuses = {
					...this.loadingStatuses,
					[workflowId]: true
				};
			})

			this.loadingNodeWorkflows = false;
			try {
				await this.loadWorkflowStatuses(activeWorkflowIds)
			} finally {
				activeWorkflowIds.forEach(workflowId => {
					this.loadingStatuses = {
						...this.loadingStatuses,
						[workflowId]: false
					};
				})
			}
		},
		async loadVendorWorkflows() {
			this.loading = true
			try {
				const workflows = await this.service.listWorkflows(this.vendorId, {
					with: ['statuses']
				})

				if (workflows.length === 0) {
					this.hasInitialWorkflows = false
				}
				this.workflows = workflows.map(workflow => ({ ...workflow, isActive: false }))
				this.setWorkflows(workflows)
			} finally {
				this.loading = false
			}
		},
		async loadWorkflowStatuses(workflowIds) {
			const promises = [...workflowIds].map(workflowId => this.service.getNodeWorkflowStatuses(this.vendorId, this.selectedNode.id, {
				node_version_id: this.selectedNode.latestVersion.id,
				workflow_id: workflowId,
				latest: true
			}))
			const statuses = (await Promise.all(promises)).flat();
			this.activeWorkflows.forEach(workflow => {
				const activeStatus = statuses.find(status => status.workflow_id === workflow.id);
				if (!activeStatus) {
					if (!this.selectedWorkflowsStatuses[workflow.id]) {
						this.updateWorkflowStatus(workflow.id, workflow.statuses[0], true);
					}
					return;
				}

				if (this.selectedWorkflowsStatuses[workflow.id] !== activeStatus.workflow_status_id) {
					this.updateWorkflowStatus(workflow.id, activeStatus);
				}
			});
		},
		labelClass(isActive) {
			const colors =  {
				true: this.isDarkModeEnabled ? '' : 'black--text',
				false: 'grey--text',
			}

			return colors[isActive]
		}
	},
}
</script>

